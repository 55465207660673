var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-page-header',{staticClass:"header_title",attrs:{"title":"Order"}}),_c('div',{staticClass:"box_content"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Delivery method"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'gender',
            {
              rules: [
                { required: true, message: 'Please select your gender!' } ],
            } ]),expression:"[\n            'gender',\n            {\n              rules: [\n                { required: true, message: 'Please select your gender!' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"","default-value":"Datahunt"},on:{"change":_vm.handleSelectChange}},[_c('a-select-option',{attrs:{"value":"Datahunt"}},[_vm._v("Datahunt")])],1)],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"size":"middle"},scopedSlots:_vm._u([{key:"Order",fn:function(text){return _c('span',{staticClass:"td_black"},[_vm._v(_vm._s(text))])}},{key:"action",fn:function(text){return _c('span',{},[_c('button',{staticClass:"btn paytosupplierbtn",on:{"click":_vm.showModal}},[_vm._v(" "+_vm._s(text.VendorStatus)+" ")])])}}])})],1),_c('a-modal',{attrs:{"title":"Title","on-ok":"handleOk"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleOk}},[_vm._v(" Confrm delivery ")])],1),_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Logistics company"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'str1',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input your Logistics company!',
                } ],
            } ]),expression:"[\n            'str1',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please input your Logistics company!',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"Courier number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'str2',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input your Courier number!',
                } ],
            } ]),expression:"[\n            'str2',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please input your Courier number!',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":""}})],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }