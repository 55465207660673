<template>
  <div>
    <a-page-header class="header_title" title="Order" />
    <div class="box_content">
      <a-form layout="inline" :form="form">
        <a-form-item label="Delivery method">
          <a-select
            v-decorator="[
              'gender',
              {
                rules: [
                  { required: true, message: 'Please select your gender!' },
                ],
              },
            ]"
            placeholder=""
            @change="handleSelectChange"
            default-value="Datahunt"
          >
            <a-select-option value="Datahunt">Datahunt</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>

      <a-table :columns="columns" :data-source="data" size="middle">
        <span slot="Order" slot-scope="text" class="td_black">{{ text }}</span>
        <span slot="action" slot-scope="text">
          <button class="btn paytosupplierbtn" @click="showModal">
            {{ text.VendorStatus }}
          </button>
        </span>
      </a-table>
      
    </div>

    <a-modal v-model="visible" title="Title" on-ok="handleOk">
      <template slot="footer">
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
        >
          Confrm delivery
        </a-button>
      </template>
      <a-form layout="inline" :form="form">
        <a-form-item label="Logistics company">
          <a-input
            v-decorator="[
              'str1',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please input your Logistics company!',
                  },
                ],
              },
            ]"
            placeholder=""
          >
          </a-input>
        </a-form-item>
        <a-form-item label="Courier number">
          <a-input
            v-decorator="[
              'str2',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please input your Courier number!',
                  },
                ],
              },
            ]"
            placeholder=""
          >
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "Order",
    dataIndex: "Order",
    key: "Order",
    scopedSlots: { customRender: "Order" },
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Customer",
    dataIndex: "Customer",
    key: "Customer",
  },
  {
    title: "Total",
    dataIndex: "Total",
    key: "Total",
  },
  {
    title: "BuyerPayment",
    dataIndex: "BuyerPayment",
    key: "BuyerPayment",
  },
  {
    title: "Items",
    dataIndex: "Items",
    key: "Items",
  },
  {
    title: "DeliverMethod",
    dataIndex: "DeliverMethod",
    key: "DeliverMethod",
  },
  {
    title: "Tags",
    dataIndex: "Tags",
    key: "Tags",
  },
  {
    title: "VendorStatus",
    key: "VendorStatus",
    scopedSlots: { customRender: "action" },
  },
  {
    title: "TrackingNumber",
    dataIndex: "TrackingNumber",
    key: "TrackingNumber",
  },
];
const data = [
  {
    Order: "#1111",
    Date: "20210101",
    Customer: "nicety",
    Total: "199$",
    BuyerPayment: "Jason",
    Items: "1 item",
    DeliverMethod: "EMS",
    Tags: "Cloth",
    VendorStatus: "PAY TO SUPPLIER",
    TrackingNumber: "123",
  },
];
export default {
  name: "NodeprojectOrders",
  components: {},
  directives: {},
  data() {
    return {
      data,
      columns,
      loading: false,
      visible: false,
      form: this.$form.createForm(this, { name: "user_from" }),
    };
  },
  mounted() {},
  methods: {
    handleSelectChange() {},
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.loading = true;
      setTimeout(() => {
        this.visible = false;
        this.loading = false;
      }, 3000);
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
};
</script>

<style  scoped>
.orders .wrap_order {
  box-shadow: 0 0 10px rgba(143, 144, 165, 0.7);
  padding: 15px 0;
  overflow-x: auto;
}
.orders .td_black {
  font-weight: 550;
}
.orders .ordertable tr {
  border-bottom: 1px solid #e5e5ec;
  cursor: pointer;
}
.orders .paytosupplierbtn {
  background-color: #ff1053;
  color: #ffffff;
}
</style>